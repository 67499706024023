/* Heading */
.heading { text-align: center; color: #000000; }
.heading .heading-title {
    position: relative;
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 15px;

    &::after {
        content: ""; 
        position: absolute; 
        bottom: 0px; 
        left: 50%; 
        transform: translateX(-50%); 
        width: 150px; 
        border-bottom: 2px solid #000000;
    }
    small {
        font-size: 15px;
        font-weight: 400;
        padding-top: 1rem;
        padding-bottom: 0.5rem;
        text-transform: none;
        text-align: center;
        max-width: 768px;
        margin-left: auto;
        margin-right: auto;
    }
}
.heading .heading-subtitle { text-transform: none; }
.heading .heading-desc { margin: 0px auto; font-size: 18px; max-width: 600px; }