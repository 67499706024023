.projects {
    margin-bottom: 50px;

    .card {
        border-radius: 0rem;
        border-width: 0rem;

        .card-img-top {
            border-radius: 0rem;
        }
        .card-body {
            position: absolute;
            width: 100%;
            height: 100%;
            text-align: center;

            &::after {
                content: "";
                position: absolute;
                left: 0rem;
                right: 0rem;
                top: 0rem;
                bottom: 0rem;
                background-color: #000;
                mix-blend-mode: multiply;
                opacity: 0.3;
            }
            .btn {
                position: relative;
                transform: translateY(-50%);
                top: 50%;
                border-radius: 0rem;
                text-transform: uppercase;
                font-weight: 600;
                z-index: 10;
            }
        }
    }
}

#new-projects {
    background-color: #EFEFEF;

    .card {
        border-radius: 0rem;
        border-width: 0rem;
        margin-top: 1rem;
        margin-bottom: 1rem;

        .card-img-top { border-radius: 0rem; }
        .card-title { font-size: 15px; font-weight: 600; text-transform: uppercase; }
        .btn {
            border-radius: 0rem;
            border-width: 0.15rem;
            text-transform: uppercase;
            font-weight: 600;

            &.btn-dark, &:hover {
                color: #FFF;
            }
        }
    }

    .project-type {
        text-transform: uppercase;
        text-align: center;
        color: #8E8E8E;
        font-size: 18px;
        margin-bottom: 1rem;
    }
    .project-samples > div {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}