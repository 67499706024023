/* Footer */
$footer-background-color: #000000;
$footer-font-color: #BCBCBC;

#footer {
    background-color: $footer-background-color;
    color: $footer-font-color;
    padding-top: 45px;
    padding-bottom: 45px;

    a { color: $footer-font-color; font-size: 14px; }

    .footer-logo { height: 65px; }
    .footer-copyright { font-size: 14px; }
    .footer-about-us { text-transform: uppercase; color: #FFF; text-decoration: underline; text-underline-offset: 5px; }

    .widget {
        .widget-title { font-size: 15px; color: #FFF; text-transform: uppercase; }
        .widget-nav .nav-link { padding: 0.3rem 0rem; color: $footer-font-color; font-size: 14px; }
        .widget-content { font-size: 14px; }

        .icon-item {
            display: inline-block;
            height: 1.5rem;
            width: 1.5rem;
            background-color: #FFF;
            text-align: center;
            line-height: 1.5rem;
            border-radius: 50%;
        }
    }

    .copyright { font-size: 14px; text-align: right; }
}