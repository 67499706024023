#approach {
    padding-left: 0rem;
    padding-right: 0rem;

    .nav-tabs {
        background-color: #FFF;
        padding-top: 2rem;
        padding-bottom: 2rem;

        a {
            text-transform: uppercase;
            color: #8E8E8E;
            padding: 1.5rem;
            text-decoration: none;

            &:hover, &.active {
                text-decoration: underline;
                text-underline-offset: 0.5rem;
                text-decoration-thickness: 0.15rem;
                color: #000000;
            }
        }

    }
    .tab-content {
        padding-top: 2rem;
        padding-bottom: 2rem;
        background-color: #EFEFEF;
        text-align: center;
    }
}