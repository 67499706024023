/* Navigation */
$navbar-background-color: #FFFFFF;
$navbar-link-color: #333333;
$navbar-link-hover-color: #000000;

#navigation { background-color: $navbar-background-color; }
#navigation .navbar-brand { text-transform: uppercase; color: #000000; font-weight: 700; font-size: 30px; }
#navigation .navbar-brand .img { height: 65px; }
#navigation .navbar-toggler:focus { outline-width: 0px; }
#navigation .navbar-nav .nav-item { margin-left: 0.5rem; margin-right: 0.5rem; }
#navigation .navbar-nav .nav-link { text-transform: uppercase; color: $navbar-link-color; font-weight: 400; font-size: 16px; padding: 0.5rem 1rem }
#navigation .navbar-nav .active > .nav-link, 
#navigation .navbar-nav .nav-link.active, 
#navigation .navbar-nav .nav-link.show, 
#navigation .navbar-nav .show > .nav-link,
#navigation .navbar-nav .nav-link:focus, 
#navigation .navbar-nav .nav-link:hover { color: $navbar-link-hover-color; font-weight: 400; text-decoration: none; }
