#founders {
    background-color: #EFEFEF;

    .card {
        border-radius: 0rem;
        border-width: 0rem;
        background-color: transparent;

        .card-img-top {
            border-radius: 0rem;
        }
        .card-body {
            text-align: center;
        }
    }
}