/* Carousel */
#banner-carousel .carousel-caption {
	bottom: 50%;
	transform: translateY(50%);
	text-transform: uppercase;
}

#banner-carousel .carousel-caption h5 {
	font-size: 35px;
	border-top: 3px solid #FFF;
	border-bottom: 3px solid #FFF;
}

#banner-carousel .carousel-item::after {
	content: "";
	background-color: #000000;
	mix-blend-mode: multiply;
	opacity: 0.05;
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
}

/* Banner */
#banner {
	height: 100%;
}

#banner .banner-inner {
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center bottom;
}

#banner .banner-graphic {
	position: absolute;
	left: 0px;
	bottom: 0px;
}

/* Navigation */
#navigation {
	background-color: #FFFFFF;
}

#navigation .navbar-brand {
	text-transform: uppercase;
	color: #000000;
	font-weight: 700;
	font-size: 30px;
}

#navigation .navbar-brand .img {
	height: 65px;
}

#navigation .navbar-toggler:focus {
	outline-width: 0px;
}

#navigation .navbar-nav .nav-item {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

#navigation .navbar-nav .nav-link {
	text-transform: uppercase;
	color: #333333;
	font-weight: 400;
	font-size: 16px;
	padding: 0.5rem 1rem;
}

#navigation .navbar-nav .active > .nav-link,
#navigation .navbar-nav .nav-link.active,
#navigation .navbar-nav .nav-link.show,
#navigation .navbar-nav .show > .nav-link,
#navigation .navbar-nav .nav-link:focus,
#navigation .navbar-nav .nav-link:hover {
	color: #000000;
	font-weight: 400;
	text-decoration: none;
}

/* Heading */
.heading {
	text-align: center;
	color: #000000;
}

.heading .heading-title {
	position: relative;
	text-transform: uppercase;
	font-size: 25px;
	font-weight: 600;
	margin-bottom: 20px;
	padding-bottom: 15px;
}

.heading .heading-title::after {
	content: "";
	position: absolute;
	bottom: 0px;
	left: 50%;
	transform: translateX(-50%);
	width: 150px;
	border-bottom: 2px solid #000000;
}

.heading .heading-title small {
	font-size: 15px;
	font-weight: 400;
	padding-top: 1rem;
	padding-bottom: 0.5rem;
	text-transform: none;
	text-align: center;
	max-width: 768px;
	margin-left: auto;
	margin-right: auto;
}

.heading .heading-subtitle {
	text-transform: none;
}

.heading .heading-desc {
	margin: 0px auto;
	font-size: 18px;
	max-width: 600px;
}

/* Footer */
#footer {
	background-color: #000000;
	color: #BCBCBC;
	padding-top: 45px;
	padding-bottom: 45px;
}

#footer a {
	color: #BCBCBC;
	font-size: 14px;
}

#footer .footer-logo {
	height: 65px;
}

#footer .footer-copyright {
	font-size: 14px;
}

#footer .footer-about-us {
	text-transform: uppercase;
	color: #FFF;
	text-decoration: underline;
	text-underline-offset: 5px;
}

#footer .widget .widget-title {
	font-size: 15px;
	color: #FFF;
	text-transform: uppercase;
}

#footer .widget .widget-nav .nav-link {
	padding: 0.3rem 0rem;
	color: #BCBCBC;
	font-size: 14px;
}

#footer .widget .widget-content {
	font-size: 14px;
}

#footer .widget .icon-item {
	display: inline-block;
	height: 1.5rem;
	width: 1.5rem;
	background-color: #FFF;
	text-align: center;
	line-height: 1.5rem;
	border-radius: 50%;
}

#footer .copyright {
	font-size: 14px;
	text-align: right;
}

/* Reset */
html, body {
	font-family: 'Open Sans', sans-serif;
}

/* Buttons */
.btn-anakiwa {
	text-transform: uppercase;
	background-color: #81CFFF;
	color: #193C5D;
	border-radius: 0px;
	font-weight: 600;
}

.btn-black, .btn-black:hover {
	text-transform: uppercase;
	background-color: #000000;
	color: #FFFFFF;
	border-radius: 0px;
	font-weight: 600;
}

.btn-blue, .btn-blue:hover {
	background-color: #2A3960;
	text-transform: uppercase;
	color: #81CFFF;
	border-radius: 0px;
}

.btn-hoki {
	background-color: #617A9C;
	color: #FFF;
	text-transform: uppercase;
	border-radius: 0px;
}

/* Backgrounds */
.bg-anakiwa {
	background-color: rgba(129, 207, 255, 0.2);
}

.bg-hippie-blue {
	background-color: rgba(103, 147, 182, 0.6);
}

.bg-hoki {
	background-color: #617a9c;
}

.bg-hoki-light {
	background-image: linear-gradient(to bottom, rgba(97, 122, 156, 0.75) 50%, transparent 50%);
}

.bg-green-smoke {
	background-color: #a9a86d;
}

.bg-green-smoke-light {
	background-image: linear-gradient(to bottom, rgba(169, 168, 109, 0.75) 50%, transparent 50%);
}

.bg-napa {
	background-color: #aca495;
}

.bg-napa-light {
	background-image: linear-gradient(to bottom, rgba(172, 164, 149, 0.75) 50%, transparent 50%);
}

.bg-manatee {
	background-color: #8d87a9;
}

.bg-manatee-light {
	background-image: linear-gradient(to bottom, rgba(141, 135, 169, 0.75) 50%, transparent 50%);
}

.bg-mystic {
	background-color: #E8ECF1;
}

.bg-silver {
	background-color: #CCCCCC;
}

/* Colors */
.color-blue {
	color: #193C5D;
}

.horizontal-line {
	border-top-color: #193C5D;
	margin: 2rem 0px;
}

/* Backgrounds - Parallax */
.parallax-image {
	background-attachment: fixed;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

/* Section */
.section-inner {
	padding-top: 45px;
	padding-bottom: 45px;
}

/* Top Bar */
#bar-top {
	background-color: #81CFFF;
	padding-top: 20px;
	padding-bottom: 20px;
}

#bar-top h4 {
	margin-bottom: 0px;
	color: #193C5D;
	text-transform: uppercase;
	text-align: center;
	font-size: 20px;
	font-weight: 700;
}

/* Primary */
#primary {
	padding-left: 0px;
	padding-right: 0px;
}

#primary .content-area {
	padding: 15px 176px;
}

#primary .content-area .content {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}

#primary .content-area .content p {
	font-size: 20px;
	margin-bottom: 2rem;
	color: #444;
}

#primary .features {
	padding-top: 45px;
	padding-bottom: 45px;
}

#primary .content-area .content .heading {
	margin: 0px;
}

#primary .content-area .content .heading .heading-title {
	font-family: 'Open Sans', sans-serif;
	font-weight: 500;
	font-size: 28px;
	text-transform: none;
	color: #617a9c;
	margin: 0px;
}

#gallery-lightbox .row > div {
	margin-top: 15px;
	margin-bottom: 15px;
}

#gallery-lightbox .card {
	margin: 0px;
	border-width: 0px;
	border-radius: 0px;
	height: 100%;
}

#gallery-lightbox .card-img {
	border-radius: 0px;
	height: 100%;
}

#gallery-lightbox .card-img-overlay {
	padding: 0px;
	background-color: rgba(97, 122, 156, 0.75);
	top: 100%;
	overflow: hidden;
	height: 0;
	transition: .5s ease;
}

#gallery-lightbox .card:hover .card-img-overlay {
	top: 0;
	height: 100%;
}

#gallery-lightbox .card-link {
	display: table;
	width: 100%;
	height: 100%;
	padding: 1.25rem;
	text-transform: uppercase;
	color: #FFF;
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	font-size: 34px;
	text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.6);
}

#gallery-lightbox .card-link span {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
}

/* Contact */
#section-location .contact-form {
	padding: 30px;
}

#section-location .contact-form .heading-title {
	color: #193C5D;
	margin-bottom: 30px;
}

#section-location .contact-form .form-control {
	border-radius: 0px;
}

#section-location .contact-form .btn {
	padding-left: 30px;
	padding-right: 30px;
}

/* Home */
#section-1 .section-inner {
	padding: 45px 0px;
	text-align: left;
	background-repeat: repeat;
	background-size: contain;
	font-size: 18px;
	color: #727272;
}

#section-1 .heading-title {
	margin-bottom: 30px;
	font-size: 25px;
}

#section-1 .section-inner .btn {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	text-transform: uppercase;
	border-radius: 0px;
	padding: 0.25rem 2.5rem;
	color: #333;
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	font-size: 34px;
}

#features {
	background-color: #FFFFFF;
}

#features .section-inner {
	padding: 45px 0px;
}

#features .features-desc {
	max-width: 640px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 45px;
	text-align: center;
}

#features .card .card-body {
	background-color: #193C5D;
	color: #FFFFFF;
	text-align: center;
	position: relative;
	padding-bottom: 60px;
}

#features .card .card-title {
	padding: 15px;
	padding-top: 0px;
	font-size: 18px;
	font-weight: 600;
	text-transform: uppercase;
	color: #81CFFF;
	border-bottom: 1px solid #81CFFF;
}

#features .card .card-text {
	padding: 15px 0px;
	font-size: 15px;
}

#features .card .btn {
	position: absolute;
	min-width: 120px;
	bottom: 30px;
	left: 50%;
	transform: translateX(-50%);
}

.max-width-768 {
	max-width: 768px;
	margin-left: auto;
	margin-right: auto;
}

.max-width-640 {
	max-width: 640px;
	margin-left: auto;
	margin-right: auto;
}

#our-portfolio {
	background-color: #EFEFEF;
}

#our-partners .img {
	max-height: 96px;
}

#section-network ul {
	margin-top: 30px;
	color: #193C5D;
}

#section-network div > ul > li {
	margin-bottom: 30px;
}

#section-3 {
	background-color: #485984;
}

#section-3 .section-inner {
	padding: 45px 0px;
}

#section-3 .card {
	background-color: transparent;
	border-width: 0px;
	color: #FFFFFF;
	text-align: center;
}

#section-3 .card-title {
	font-size: 18px;
}

#section-3 .card-img-top {
	display: block;
	padding-left: 15px;
	padding-right: 15px;
	width: auto;
	height: 120px;
	margin-left: auto;
	margin-right: auto;
}

#section-4 .section-inner {
	padding: 45px 0px;
	text-align: center;
}

#section-4 .heading-title {
	color: #485984;
	font-size: 25px;
	text-transform: uppercase;
	text-align: center;
	border-bottom: 1px solid #485984;
	padding-bottom: 15px;
}

#section-4 .btn {
	margin-left: auto;
	margin-right: auto;
	text-transform: uppercase;
	background-color: #485984;
	color: #FFFFFF;
	border-radius: 0px;
	padding-left: 30px;
	padding-right: 30px;
}

#section-5 .section-inner {
	padding-bottom: 45px;
}

#section-5 .heading-title {
	color: #485984;
}

#section-5 .heading-desc {
	color: #727272;
	max-width: unset;
}

/* FAQs */
#faqs {
	margin-bottom: 0px;
}

#faqs .faqs-inner {
	position: relative;
	height: 300px;
	text-align: center;
	background-repeat: repeat-y;
	background-size: contain;
}

#faqs .faqs-inner:after {
	content: "";
	background-color: rgba(0, 0, 0, 0.3);
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
}

#faqs .faqs-inner .btn {
	position: relative;
	z-index: 1;
	background-color: #2A3960;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	text-transform: uppercase;
	border-radius: 0px;
	padding: 0.25rem 2.5rem;
	color: #FFF;
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	font-size: 34px;
}

#faqs-accordion {
	padding-bottom: 30px;
	margin-bottom: 15px;
}

#faqs-accordion .card {
	margin-top: 15px;
	border-width: 0px;
	border-radius: 0px;
	background-color: transparent;
}

#faqs-accordion .card-header {
	border-width: 0px;
	background-color: transparent;
	padding: 0px;
}

#faqs-accordion .card-header .btn-link {
	padding: 0px;
	font-size: 24px;
	color: #617A9C;
	text-decoration: none;
	position: relative;
	width: 100%;
	text-align: left;
}

#faqs-accordion .card-header .btn-link::before {
	content: '\f107';
	position: absolute;
	top: 50%;
	right: 0.8rem;
	transform: translateY(-50%);
	display: block;
	font-family: 'FontAwesome';
	font-size: 30px;
	color: #EEE;
}

#faqs-accordion .card-header .btn-link[aria-expanded='true']::before {
	content: '\f106';
}

#faqs-accordion .card-body {
	padding: 0px;
	padding-top: 15px;
	border-bottom: 1px solid #EEE;
}

#faqs-accordion .card:last-child .card-body {
	border-bottom-width: 0px;
}

/* Connect */
#connect {
	background-color: #485984;
	padding-top: 45px;
	padding-bottom: 45px;
}

#connect .heading {
	margin-top: 15px;
	margin-bottom: 15px;
	text-align: center;
}

#connect .heading .heading-title {
	text-transform: uppercase;
	color: #FFFFFF;
	font-size: 25px;
}

#connect .contact-form {
	display: inline-block;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	margin-top: 15px;
	margin-bottom: 15px;
}

#connect .contact-form .form-control {
	border-radius: 0px;
}

#connect .contact-form .btn {
	background-color: #2A3960;
	margin-left: 10px;
	color: #FFF;
}

#section-8 {
	background-color: #D1D5E0;
	color: #485984;
}

#section-8 .section-inner {
	padding: 45px 0px;
}

#section-8 .heading-title {
	text-transform: uppercase;
	font-weight: 300;
	font-size: 25px;
	letter-spacing: 0.5rem;
}

#section-8 .icon-list {
	text-align: center;
}

#section-8 .icon-item {
	display: inline-block;
	padding: 4px;
}

#section-8 .icon-link {
	display: inline-block;
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	background-color: #485984;
	color: #FFFFFF;
	border-radius: 50%;
}

#section-9 .section-inner {
	position: relative;
	padding-top: 150px;
	padding-bottom: 150px;
	color: #FFFFFF;
	background-size: cover;
}

#section-9 .section-inner:after {
	content: "";
	background-color: rgba(72, 89, 132, 0.65);
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
}

#section-9 .section-content {
	position: relative;
	z-index: 1;
	max-width: 600px;
}

#section-9 .section-content h5 {
	font-size: 35px;
}

#section-10 {
	background-color: #D1D5E0;
}

#section-10 .section-inner {
	padding: 45px 0px;
	text-align: left;
	background-repeat: repeat;
	background-size: contain;
	font-size: 20px;
	color: #727272;
}

#section-10 .heading-title {
	margin-bottom: 30px;
	font-size: 25px;
}

#section-10 .section-inner .btn {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	text-transform: uppercase;
	border-radius: 0px;
	padding: 0.25rem 2.5rem;
	color: #333;
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	font-size: 34px;
}

#section-goals {
	color: #193C5D;
}

#section-goals .goal-number {
	display: block;
	position: relative;
	font-size: 20px;
	font-weight: 600;
	padding-bottom: 5px;
	margin-bottom: 5px;
}

#section-goals .goal-number::after {
	content: "";
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 20px;
	border-bottom: 1px solid #193C5D;
}

#section-goals .content > div {
	margin-top: 15px;
	margin-bottom: 15px;
}

#section-principles .card {
	background-color: transparent;
	border-width: 0px;
	color: #2A3960;
	text-align: center;
}

#section-principles .card-img-top {
	display: block;
	background-color: #D6EFFF;
	width: 150px;
	height: 150px;
	margin-left: auto;
	margin-right: auto;
	border-radius: 50%;
	line-height: 150px;
}

#section-principles .card-img-top img {
	height: 90px;
	width: auto;
}

#section-principles .card-title {
	font-size: 18px;
}

#section-concept .heading-title::after {
	border-bottom-width: 0px;
}

#section-art .art-gallery > div {
	padding: 0px;
}

#section-network .content-title {
	max-width: 640px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
	font-size: 18px;
	color: #193C5D;
	font-weight: 600;
	text-align: center;
}

#section-12 .section-inner {
	padding-top: 0px;
	padding-bottom: 0px;
}

#section-13 .section-inner {
	padding: 45px 0px;
	text-align: left;
	background-repeat: repeat;
	background-size: contain;
	font-size: 20px;
	color: #727272;
}

#section-13 .heading-title {
	margin-bottom: 30px;
	font-size: 25px;
	color: #485984;
}

#section-13 .heading-desc {
	color: #727272;
}

#section-13 .section-inner .btn {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	text-transform: uppercase;
	border-radius: 0px;
	padding: 0.25rem 2.5rem;
	color: #333;
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	font-size: 34px;
}

#section-14 {
	background-color: #D1D5E0;
}

#section-14 .section-inner {
	padding: 45px 0px;
	text-align: left;
	background-repeat: repeat;
	background-size: contain;
	font-size: 20px;
	color: #727272;
}

#section-14 .heading-title {
	padding-bottom: 15px;
	margin-bottom: 15px;
	font-size: 25px;
	text-transform: uppercase;
	color: #485984;
	border-bottom: 1px solid #485984;
}

#section-14 .section-inner .btn {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	text-transform: uppercase;
	border-radius: 0px;
	padding: 0.25rem 2.5rem;
	color: #333;
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	font-size: 34px;
}

#section-14 img {
	height: 50%;
}

#section-15 {
	border-bottom: 1px solid #485984;
}

#section-15 .section-inner {
	padding: 45px 0px;
	text-align: left;
	background-repeat: repeat;
	background-size: contain;
	font-size: 20px;
	color: #727272;
}

#section-15 .heading {
	margin-top: 0px;
	max-width: 600px;
}

#section-15 .heading-title {
	margin-bottom: 30px;
	font-size: 25px;
	color: #2A3960;
	text-transform: none;
}

#section-15 .section-content .heading-title {
	color: #485984;
	border-bottom: 1px solid #485984;
}

#section-15 .section-inner .btn {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	text-transform: uppercase;
	border-radius: 0px;
	padding: 0.25rem 2.5rem;
	color: #333;
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	font-size: 34px;
}

#section-16 .section-inner {
	padding: 45px 0px;
	text-align: left;
	background-repeat: repeat;
	background-size: contain;
	font-size: 20px;
	color: #727272;
}

#section-16 .heading {
	margin-top: 0px;
	max-width: 600px;
}

#section-16 .heading-title {
	margin-bottom: 30px;
	font-size: 25px;
	color: #2A3960;
	text-transform: none;
}

#section-16 .section-content .heading-title {
	color: #485984;
	border-bottom: 1px solid #485984;
}

#section-16 .section-inner .btn {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	text-transform: uppercase;
	border-radius: 0px;
	padding: 0.25rem 2.5rem;
	color: #333;
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	font-size: 34px;
}

#section-16 .card {
	border-width: 0px;
}

#section-16 .card-body {
	background-color: #D1D5E0;
	padding: 0px;
}

#section-16 .card-title {
	margin-bottom: 0px;
	padding: 15px;
	height: 90px;
	line-height: 30px;
	background-color: #2A3960;
	color: #FFFFFF;
}

#section-16 .card-text {
	padding: 15px;
}

#section-17 .section-inner {
	padding: 45px 0px;
	text-align: left;
	background-repeat: repeat;
	background-size: contain;
	font-size: 20px;
	color: #727272;
}

#section-17 .heading-title {
	margin-bottom: 30px;
	font-size: 25px;
	color: #485984;
}

#section-17 .heading-desc {
	color: #727272;
}

#section-17 .btn {
	text-transform: uppercase;
	color: #FFFFFF;
	background-color: #2A3960;
	padding-left: 30px;
	padding-right: 30px;
	border-radius: 0px;
}

#section-18 .section-inner {
	padding: 45px 0px;
	text-align: center;
}

#section-18 .heading {
	margin-top: 0px;
}

#section-18 .heading-title {
	color: #485984;
	font-size: 25px;
	text-transform: uppercase;
	text-align: center;
	padding-bottom: 15px;
}

#section-18 .heading-desc {
	color: #727272;
}

/* Open House */
#primary .open-house-info {
	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
	padding-right: 15px;
	max-width: 768px;
}

#primary .open-house-info .heading, #primary .open-house-info .content {
	text-align: center;
}

#primary .open-house-info .content .session-info {
	border-top: 1px solid #617a9c;
	border-bottom: 1px solid #617a9c;
	color: #617a9c;
	padding: 30px;
}

#primary .open-house-timeslots {
	background-color: #e8ecf1;
}

#our-portfolio .swiper-button-next {
	color: #000;
}

#our-portfolio .swiper-button-prev {
	color: #000;
}

#our-portfolio hr {
	border-top-color: #000;
	border-top-width: 2px;
	max-width: 60px;
	margin-left: 0rem;
}

#our-portfolio .btn {
	border-radius: 0rem;
	text-transform: uppercase;
	font-weight: 600;
}

@media (min-width: 768px) and (max-width: 1024px) {
	#features .card .card-title {
		min-height: 60px;
	}
}

.projects {
	margin-bottom: 50px;
}

.projects .card {
	border-radius: 0rem;
	border-width: 0rem;
}

.projects .card .card-img-top {
	border-radius: 0rem;
}

.projects .card .card-body {
	position: absolute;
	width: 100%;
	height: 100%;
	text-align: center;
}

.projects .card .card-body::after {
	content: "";
	position: absolute;
	left: 0rem;
	right: 0rem;
	top: 0rem;
	bottom: 0rem;
	background-color: #000;
	mix-blend-mode: multiply;
	opacity: 0.3;
}

.projects .card .card-body .btn {
	position: relative;
	transform: translateY(-50%);
	top: 50%;
	border-radius: 0rem;
	text-transform: uppercase;
	font-weight: 600;
	z-index: 10;
}

#new-projects {
	background-color: #EFEFEF;
}

#new-projects .card {
	border-radius: 0rem;
	border-width: 0rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

#new-projects .card .card-img-top {
	border-radius: 0rem;
}

#new-projects .card .card-title {
	font-size: 15px;
	font-weight: 600;
	text-transform: uppercase;
}

#new-projects .card .btn {
	border-radius: 0rem;
	border-width: 0.15rem;
	text-transform: uppercase;
	font-weight: 600;
}

#new-projects .card .btn.btn-dark, #new-projects .card .btn:hover {
	color: #FFF;
}

#new-projects .project-type {
	text-transform: uppercase;
	text-align: center;
	color: #8E8E8E;
	font-size: 18px;
	margin-bottom: 1rem;
}

#new-projects .project-samples > div {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

#founders {
	background-color: #EFEFEF;
}

#founders .card {
	border-radius: 0rem;
	border-width: 0rem;
	background-color: transparent;
}

#founders .card .card-img-top {
	border-radius: 0rem;
}

#founders .card .card-body {
	text-align: center;
}

#approach {
	padding-left: 0rem;
	padding-right: 0rem;
}

#approach .nav-tabs {
	background-color: #FFF;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

#approach .nav-tabs a {
	text-transform: uppercase;
	color: #8E8E8E;
	padding: 1.5rem;
	text-decoration: none;
}

#approach .nav-tabs a:hover, #approach .nav-tabs a.active {
	text-decoration: underline;
	text-underline-offset: 0.5rem;
	text-decoration-thickness: 0.15rem;
	color: #000000;
}

#approach .tab-content {
	padding-top: 2rem;
	padding-bottom: 2rem;
	background-color: #EFEFEF;
	text-align: center;
}

#services {
	background-color: #EFEFEF;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

#contact {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

#contact .heading-title {
	text-align: left;
}

#contact .heading-title::after {
	left: 0rem;
	transform: translateX(0rem);
}

#contact-form .form-control {
	border-radius: 0rem;
	box-shadow: none;
}

#contact-form .btn {
	border-radius: 0rem;
	text-transform: uppercase;
	padding-left: 2rem;
	padding-right: 2rem;
	font-weight: 600;
}
