#services {
    background-color: #EFEFEF;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

#contact {
    padding-top: 2rem;
    padding-bottom: 2rem;

    .heading-title {
        text-align: left;

        &::after {
            left: 0rem;
            transform: translateX(0rem);
        }
    }
}

#contact-form {
    .form-control {
        border-radius: 0rem;
        box-shadow: none;
    }

    .btn {
        border-radius: 0rem;
        text-transform: uppercase;
        padding-left: 2rem;
        padding-right: 2rem;
        font-weight: 600;
    }
}